

 .feedBack__btn {
    color: #026937;
    border: 1px solid #026937;
    background: none;
    width: 15%;
    padding: 5px;
    border-radius: 7px;
    font-family: 'Poppins';
    outline: none;

 }
 .help-center-title{
  border: 1px solid #ECECEC;
  border-radius: 8px;
  background-color: #ECECEC;
  height: 80px;
  padding: 25px 20px;
  margin-inline: 5vw ;
  margin-top: 120px;
 }

 .feedBack__submitBtn {
    color: #ffff;
    border: none;
    width: 15%;
    padding: 5px;
    border-radius: 7px;
    font-family: 'Poppins';
    background: #026937;
    position: relative;
    left: -30%;
    outline: none;
 }