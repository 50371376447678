.analytics-section1{
    margin-top: 30px;
    padding-left: 150px ;
    padding-right: 150px ;
    height: 150vh;
    overflow-y: auto;
    margin-bottom: 150px;
    
}
.transaction-tab-section{
    padding-top: 20px ;
    padding-left: 40px ;
}
.tile-section{
    margin-left: 70px;
    width: 64vw;
    justify-content: space-between;
    /* text-align: left; */
}
.box-tile-section1{
    margin-left: 8vw;
}
.analytics-tile{
    height: 200px;
    width: 24vw;
    border: 1px solid #ECECEC;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    /* background-color: #ECECEC; */
    border-radius: 12px;
    padding-top: 60px;
    padding-left: 17px;
}
.box-tile-1{
    height: auto;
    width: 64vw;
    padding: 20px 12px;
    border: 1px solid #ECECEC;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    /* background-color: #ECECEC; */
    border-radius: 12px;
}
.box-tile-2{
    height: auto;
    width: 580px;
    padding: 20px 12px;
    border: 1px solid #ECECEC;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    /* background-color: #ECECEC; */
    border-radius: 12px;
}
.filter-bar{
    border: 1px solid #C8C8C8;
    border-radius: 8px;
    padding: 5px;
}
.submit-btn-date{
    border-radius: 8px;
    padding: 3px 4px;
}
.body__elemnts{
    font-size: 15px;
    text-wrap: wrap;
}
.revenue-detail{
    justify-content: space-between;
    width: 61vw;
}
.revenue-total-section{
    margin-left: 70px;
    padding-inline: 20px;
    /* width: 62vw; */
}