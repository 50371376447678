.business-logo-container{
    display: flex;
    /* align-items: center; */

}
.text_area{
    /* background-color: #f5f7f86e; */
    height: 89px;
    border-radius: 10px;
}
.delete-btn{
    width: 45px;
    margin-right: 50px;
    height: 30px;
    font-weight: 400;
}
.profile-image{
    height: 50px;
    width: 50px;
}