.main_container {
    width: 100%;
    height: 70vh;
    overflow: hidden;
    background-color: #ffff;
    margin: 0 auto;
    max-height: 600px;
    max-width: 98%;

    /* Mobile and iPad Styles */
    @media (max-width: 767px) {
        max-height: 230px;
        max-width: 95%;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    }
}

.logo_conatiner {
    width: 100%;
    background-color: #ECECEC;
    height: 41vh;
    margin-top: 15px;

    @media (max-width: 767px) {
        height: 10vh;
        margin-top: 20px;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    }
}

.logoImg {
    height: 70px;
    text-align: center;
    display: block;
    margin: auto;
    position: relative;
    top: 75px;
    image-rendering: pixelated;
    background-size: cover;
    filter: contrast(1.2) brightness(1.1);

    @media (max-width: 767px) {
        height: 30px;
        top: 35px; 
    }
}
