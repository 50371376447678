.error-mess {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #FF0000;
    position: relative;
    bottom: 7px;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.modal-header {
    border: none !important;
}

.modal-body {
    border: none !important;
    color: #183964;

}

.close_ok_btn {
    width: 25%;
    background: #026937;
    color: #fff;
    font-family: 'Poppins';
    border: none;
    padding: 5px;
    margin-top: 15px;
    border-radius: 4px;
    font-weight: 600;
}

.myModal .btn-close {
    display: none;
}

.modalImg {
    position: relative;
    left: 159px;
    /* top: 10px; */
    padding: 10px;

    @media (max-width: 767px) {
           left: 130px;
           padding: 0px;
    }
}



.modal-content {
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
}

.subTxt {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    /* line-height: 63px; */
    letter-spacing: 0px;
    text-align: auto;
    margin-bottom: 10px;

}
.mainTxt{
    font-weight: 700;
    font-family: Poppins;
}