.product_card_image{
    width:3.5vw;
    height: 9vh;
   
}

.inventory-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgba(0, 0, 0, 1);
    border-radius: 15px;
    padding: 5px 10px;
    width: 40vw;
    gap: 20px;
}

.action{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    
}

.inventory-text{
    width:200px
}