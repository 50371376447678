.specific__content {
    max-width: 80%;
    margin: 0 auto;
}

.specific__txt {
    font-size: 17px;
    font-weight: 600;
}
.image-product-inquiry{
    border: 1px solid black;
    object-fit: contain;
    height: 100px;
    width: 100px;
}

.image_buisness {
   
    max-height: 330px;
    /* Adjust the maximum height as per your requirement */
    height: auto;
    /* Allows the image to maintain its aspect ratio */
    object-fit: contain;
    padding: 10px;
}

.specific__subtxt {
    font-family: "Poppins";
    font-size: 14px;
    margin-bottom: 10px;
}

.specific__btn {
    background-color: #026937;
    color: #ffff;
    width: 40%;
    border: none;
    font-family: 'Poppins';
    padding: 7px;
    border-radius: 7px;
    text-align: center;
}

.img_product {
    height: 275px;
    width: 253px;
    object-fit: cover;
}