.inventory__label {
    font-family: 'Poppins';
    margin-bottom: 5px;
}

.inventory__inputBox {
    border: 1px solid #DDDDDD;
    padding: 5px;
    height: 40px;
    font-family: 'Poppins';
    outline: none;
    border-radius: 8px;
    /* color: #AAAAAA; */
}
.addinvetory-title-section{
    border: 1px solid #ECECEC;
    background-color: #ECECEC;
    margin-top: 120px;
    border-radius: 8px;
    margin-inline: 4vw;
    padding-left: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.inventory__Descbox {
    font-family: 'Poppins';
    padding: 7px;
    border: 1px solid #DDDDDD;
    border-radius: 8px;
    outline: none;
}

.inventory__label {
    font-family: 'Poppins';
    margin-bottom: 5px;
}

.inventory__inputBox,
.inventory__Descbox {
    font-family: 'Poppins';
    padding: 7px;
    border: 1px solid #DDDDDD;
    border-radius: 8px;
    outline: none;
}

.inventory__inputBox::placeholder,
.inventory__Descbox::placeholder {
    color: #AAAAAA;
}

.addinventory_btn{
    background-color: rgba(111, 191, 73, 1) !important;
    color: white !important;
    padding: 5px 20px !important;
    border: none;
    border-radius: 10px;
}