* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


body {
  background: #fcfcfc;
  font-family: "Poppins", sans-serif
}


h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif !important;
  color: #001B35
}


body::-webkit-scrollbar {
  display: none;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="checkbox"] {
    font-family: Inter, sans-serif;
}

::-webkit-input-placeholder {
  font-family: Inter;
  /* font-size: 20px; */
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #001B35;

}


/* ::selection {
  color: #001B35;
  background-color: #026937;
  
} */


