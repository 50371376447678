.order-history-page{
    /* height: 250vh; */
}
.order-history-section{
    margin-top: 160px;
    overflow-y: auto;
    max-height: 100vh;
scroll-behavior: smooth;
}
.title-order-history{
    border: 1px solid #ECECEC;
    background-color: #ECECEC;
    color: #000;
    margin-right: 60px;
    margin-left: 70px;
    border-radius: 10px 10px 0 0;
}
.table-header{
    background-color: #F4F4F4;
}
.table-header th{
    color: #6E6E6E;
    font-weight: 400;
}