.homePage__container {
    margin-bottom: 5%;
    margin-top: 7%;
}

.activeHeader {
    /* border: 1px solid #6FBF49;
    background-color: #6FBF49; */
    color: #fff;
}

@media only screen and (max-width: 992px) {
    .homePage__container {
        margin-bottom: 30%;
        margin-top: 35%;
    }

}

.products__item {
    border: 1px solid #D3D3D3;
    text-align: center;
    /* padding: 5px; */
    cursor: pointer;
    border-radius: 25px;
}

.products__img {
    margin-top: 15px;
    margin-left: 25px;
    margin-right: 25px;
    transition: 0.4s;
    /* border: 1px solid green; */
    object-fit: contain;
    overflow: hidden;

}

.products__img:hover {
    transform: scale(1.1);
}



.products__content h6 {
    position: relative;
    top: 10px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    color: #ffff;

}

.products__content p {
    font-size: 12px;
    font-weight: 400;
    font-family: 'Poppins';
    color: #ffff;

}

.products__content {
    background-color: #7AC24F;
    position: relative;
    top: 18px;
    border-radius: 0px 0px 25px 25px;
}

.product__loaction {
    position: relative;
    bottom: 10px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 700;
    color: #ffff;

}

.bussiness__img {
    object-fit: contain;
    overflow: hidden;
}

@media only screen and (max-width: 992px) {
    .product__content h5 a {
        font-size: 0.8rem;
    }

    .product__item {
        padding: 20px;
    }

    /* .addToBuy__btn {
        padding: 5px 15px;
        font-size: 0.7rem;
    } */

    .bussiness__img {
        height: 25%;
        width: 85%;
    }
}