.analytics-section{
    margin-top: 150px;
    padding-left: 100px ;
    padding-right: 100px ;
    height: 150vh;
    overflow-y: auto;
    margin-bottom: 150px;
    
}
.transaction-tab-section{
    padding-top: 20px ;
    padding-left: 40px ;
}
.tile-section1{
    justify-content: space-between;
    /* column-gap: 50px; */
    margin-left: 70px;
    width: 1040px;
}
.box-tile-section1{
    /* justify-content: space-around; */
    margin-left: 70px !important;
    /* column-gap: 100px; */
    
}
.analytics-tile1{
    height: 196px;
    width: 280px;
    border: 1px solid #ECECEC;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    /* background-color: #ECECEC; */
    border-radius: 12px;
    padding-top: 60px;
    padding-left: 17px;
}
.admin-box-tile-1{
    height: auto;
    width: 500px;
    padding: 20px 12px;
    border: 1px solid #ECECEC;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    /* background-color: #ECECEC; */
    border-radius: 12px;
}
.admin-box-tile-2{
    height: auto;
    width: 500px;
    margin-left: 40px;
    padding: 20px 12px;
    border: 1px solid #ECECEC;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    /* background-color: #ECECEC; */
    border-radius: 12px;
    overflow-y: auto;
    max-height: 650px;
}
.filter-bar{
    border: 1px solid #C8C8C8;
    border-radius: 8px;
    padding: 5px;
}
.submit-btn-date{
    border-radius: 8px;
    padding: 3px 4px;
    background-color: transparent;
    border: 1px solid #7AC24F;
    color: #7AC24F;
}
.admin-revenue-detail{
    margin-left: 60px; 
    justify-content: space-between;
    width: 470px;
    /* width: 480px; */
}
