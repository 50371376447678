.bodyText {
    font-family: 'Poppins';
    font-size: 15px;
    padding: 25px;
    margin: 5px;
    position: relative;
    bottom: 20px;
    color: #1A1A1A;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 1.8;
    cursor: pointer;
}

.othersInput {
    padding: 10px;
    outline: none;
    border: 1px solid #A6A6A6;
    border-radius: 8px;
    margin-top: 20px;
}

.confirmBtn {
    background-color: #7AC24F;
    padding: 8px 107px 9px 91px;
    text-align: center;
    font-family: 'Poppins';
    font-size: 14px;
    border: none;
    margin-top: 15px;
}
.selectedOption {
    font-weight: bold;
    color: #007bff; 
    font-size: 16px;
  }