.inventory-card {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    column-gap: 5vw;
}
.inventory-title-section{
    margin-top: 150px;
    border: 1px solid #ECECEC;
    border-radius: 8px;
    background-color: #ECECEC;
    height: 80px;
    padding: 25px 20px;
    margin-inline: 5vw ;
}
.center-section-inventory{
    margin-left: 5vw;
}
.containerMain{
    /* align-items: center; */
}

.inventory_btn{
    background-color: rgba(111, 191, 73, 1) !important;
    color: white !important;
    padding: 10px 20px !important;
    width: 300px;
    border: none;
    border-radius: 10px;
    
}

.inventory_btn:hover {
    color: white;
    background-color: rgba(111, 191, 73, 1) !important;
    border-color: rgba(111, 191, 73, 1) !important;
}