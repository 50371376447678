.read_txt {
    color: #2E70E8;
    font-family: 'Poppins';
}
.allContainer{
    border-bottom: 1px solid rgba(226, 232, 240, 1);
}
.filter-section-notification{
    border: 1px solid #d4d1d1;
    border-radius: 8px;
}
.close_icon {
    color: #64748B;
    font-family: 'Poppins';
    margin-left: 10px;
}
.badge {
    background-color: rgba(242, 244, 247, 1);
    color: black;
    padding: 4px 8px;
    text-align: center;
    border-radius: 50%;
  }
.notif_all {
    font-family: inter;
    font-weight: 600;
    color: #1E293B;
    font-weight: 600;
    color: #1E293B;
    border-bottom: 5px solid rgba(46, 112, 232, 1);
    width: 80px;
    margin-bottom: 0px;
}

.noti__img {
    height: 50px;
    width: 50px;
    object-fit: cover;
}

.noti__subtxt {
    color: #334155;
    /* position: relative; */
    margin-bottom: 6px;
    margin-top: -9px;
    font-weight: 400;
    font-family: 'Poppins';
    position: relative;
    left: 115px;
    top: -15px;
    font-size: 13px;
}

.noti__time {
    font-family: 'Poppins';
    position: relative;
    left: -10px;

}

.noti__btn {
    background-color: #026937;
    color: #fff;
    font-family: 'Poppins';
    border-radius: 5px;
    padding: 6px;
    border: none;
    margin-top: 2px;
    margin-bottom: 10px;
    position: relative;
    left: -85%;
    font-size: 12px;

}