.app-header {
    background: rgba(236, 236, 236, 1);
    color: rgba(85, 86, 90, 1);
    cursor: pointer;
    padding: 0px;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000; /* Adjust this value based on your layout */
}

.icon {
    margin-right: 5px;
    color: rgba(85, 86, 90, 1);;
    cursor: pointer;
}

.app-logo {
    padding: 24px;
    height: 90px;
    cursor: pointer;
}

.app-text {
    font-family: "Poppins", sans-serif !important;
    color: rgba(85, 86, 90, 1);;
    position: relative;
    right: 4px;
}

.search-container {
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 20px;
}

.search-icon {
    color: grey;
    position: absolute;
    left: 90%;
    top: 50%;
    transform: translateY(-50%);
}

.search-bar {
    flex: 1;
    border: none;
    border-radius: 20px;
    padding: 8px;
    outline: none;
    /* width: 600px; */
}

.cart__badge {
    position: absolute;
    top: -12px;
    right: 45px;
    background: #fff;
    color: #007d8a;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 10px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
}

.search-bar::placeholder {
    position: relative;
    left: 10px;
    letter-spacing: 2px;
    color: #25243B;
    font-family: "Poppins", sans-serif !important;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: -0.5px;
    line-height: 22px;
}

.model_header {
    /* background-color: #007D8A;
    color: #fff; */
    background-color: rgba(236, 236, 236, 1);
    color: rgba(85, 86, 90, 1);
    padding: 10px;
    font-family: 'Poppins';
}

.modal__footer {
    display: flex;
    justify-content: space-evenly;
}

.modal__txt {
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    position: relative;
    top: 10px;
}

.cancel__btn {
    background-color: #AEAEAE;
    border: none;
    outline: none;
    width: 25%;
    font-family: 'Poppins';
    font-size: 14px;
    padding: 5px;
    color: #ffff;
    border-radius: 5px;
}

.yes__btn {
    background-color: #7AC24F;
    border: none;
    outline: none;
    width: 25%;
    font-family: 'Poppins';
    font-size: 14px;
    padding: 5px;
    color: #ffff;
    border-radius: 5px;
}

.offcanavs__conatiner {
    background-color: #7AC24F;
    color: #ffff;
    font-family: 'Poppins';
}
.offcanvas-header button.close {
    color: #ffff; /* Change 'red' to the desired color */
  }


@media (max-width: 768px) {

    .app-header {
        height: 160px;
    }

    .app-logo {
        padding: 12px;
        height: 50px;
        position: relative;
        top: 10px;
    }

    .ml-auto {
        margin-left: auto;
        position: relative;
        left: -7px;
        top: -45px;
        padding: 10px;
    }

    .search-container {
        position: relative;
        bottom: 30px;
    }

    .search-bar {
        width: 100%;
    }

    .icon {
        margin-right: 10px;

    }

    .search-bar::placeholder {
        font-size: 12px;
        letter-spacing: 1px;
    }

    .search-bar::placeholder {
        content: "Search Products";
    }

    .modal__txt {
        font-size: 12px;
    }

    .cancel__btn {
        font-size: 12px;
    }

    .yes__btn {
        font-size: 12px;
    }

}