.my-profile-bsuiness-page{
    height: 140vh;
    scroll-behavior: auto;
}
.profile-section{
    margin-top: 150px;
    margin-left: 100px;
    margin-right: 100px;

}
.my-profile-bar{
    /* border: 1px solid #007D8A; */
    border-radius: 10px 10px 0 0;
    background-color: #ECECEC ;
    padding: 20px 45px;
    width: 93%;
}
.my-profile-bar-text{
    color: black;
    font-size: 24px;
    /* border: 1px solid #007D8A; */
    border-radius: 10px, 10px, 0px, 0px;
}

.input-my-profile-section{
    margin-top: 36px;
    border: 1px solid black;
    border-radius: 50%;
    width: 200px;
    height: 200px;
    object-fit: contain;
    text-align: center;
    padding: 24px;
}
.image-profile-section{
    object-fit: contain;
    height: 140px;
    width: 140px;
    /* position: relative; */
}
.text-area-location{
    width: 460px;
    height: 77px;
    border-radius: 10px;
    border: 1px solid #5E5858;
}
.input-business-name{
    width: 367px;
    height: 50px;
    border-radius: 10px;
    border: 1px solid #5E5858;
}
.btn-first-container{

    display: flex;
    justify-content: space-between;
}
.first-container-buttons{
    width: 370px;
}
.second-container-buttons{
    width: 370px;
}
.edit-btn1{
  /* width: 10vw; */
  padding: 5px 60px;
  border: 1px solid #7AC24F;  
  background-color: transparent;
  border-radius: 5px;
  cursor: pointer;
  color: #7AC24F;
}
.edit-btn:hover{
color: #002413;
}
.submit-btn{
    width: 10vw;
    border: 2px solid #7AC24F;  
background-color: #7AC24F;  
color: #fff;
border-radius: 5px;
cursor: pointer;
}

.label-image{
    cursor: pointer;
    position: relative;
}