.my-review-section{
    margin-top: 20px;
    margin-right: 4vw;
}
.table-reviews{
    margin-top: 20px;
}
.profImage{
    height: 40px;
    width: 40px;
}
.title-section{
    border: 1px solid #ECECEC;
    background-color: #ECECEC;
    margin-top: 120px;
    border-radius: 8px;
    margin-inline: 4vw;
    padding-left: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.reviews-header-section{
    background-color: #F4F4F4;
    /* padding: 14px 30px; */
}
.t-head-reviews{
    padding: 14px 20px;
}
.profile-container{
    display: flex;
    align-items: center;
    padding: 11px 16px;
}
.reviews-header-section th{
    color: #6E6E6E;
}
.input-msg{
    border-radius: 5px;
    background-color: #F4F4F4;
    border:1px solid #f4f4f437;
    width: 350px;
}
