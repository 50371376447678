.sticky-header {
    position: sticky;
    top: 0;
    z-index: 100;
}

/* Your existing styles for larger screens */
.logo-terms {
    height: 56px;
    display: flex;
    justify-content: flex-start;
    position: relative;
    left: 13px;
    padding: 7px;
    /* width: auto; */
    cursor: pointer;
}

/* Media query for mobile devices */
@media only screen and (max-width: 767px) {
    .logo-terms {
        margin-right: 75px;
        position: relative;
        left: 0px;
    }
}


.termTxt {
    color: #ffff;
    text-align: start;
    position: relative;
    left: 100px;
    top: 5px;
    font-size: 18px;
}

@media (max-width: 767px) {
    .termTxt {
        position: relative;
        left: 20px;
    }
}


.mainText {
    /* overflowY: auto; */
    margin-top: 20px;
    max-width: 86%;
    margin: 0 auto;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 90px;
}