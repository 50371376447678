.bussinessPage__Conatiner {
  margin-bottom: 5%;
  margin-top: 1vh;
}
.specific-business-title{
  display: flex;
  justify-content: space-between;
  border: 1px solid #ECECEC;
  border-radius: 8px;
  background-color: #ECECEC;
  height: 80px;
  padding-right: 30px;
  padding-left: 30px;
  /* padding: 25px 20px; */
  margin-inline: 5vw ;
  margin-top: 120px;
}
.product__img{
  height: 170px;
  object-fit: contain;
  overflow: hidden;
  padding-top: 20px;
  position: relative;
}
.product__img img{
  object-fit: contain;
  padding: 10px;
  /* border: 1px solid red; */
  height: 145px;
}
.tooltip_id{
  position: absolute;
  right: 100px;
  /* margin-top: 50px; */
}

.dashboard-container {
  margin-top: 25%;
}

.bottom__conatiner {
  
  /* top: 45px; */
  left: 50px;
}
.text-cart{
  text-align: center;
}

.bottom_txt {
  color: #00A0DD;
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 600;
  width: 140px;
  text-align: center;
  margin-right: 50px;
}

.bottom_btn {
  width: 320%;
  background: #026937;
  color: #fff;
  border: none;
  padding: 6px;
  font-family: 'Poppins';
  border-radius: 3px;
}

.bussiness--btn {
  /* width: 100px; */
  display: flex;
}
.bussiness--btn button {
  width: 200px;
}

.send__inquiry {
  width: 30%;
  background: #fff;
  color: #026937;
  border: 1px solid #026937;
  padding: 8px;
  border-radius: 7px;
  font-family: 'Inter';
  font-weight: 600;
  outline: none;
}

.rate__bussiness {
  width: 30%;
  background: #026937;
  color: #fff;
  border: 1px solid #026937;
  padding: 8px;
  border-radius: 7px;
  font-family: 'Inter';
  font-weight: 600;
}

.rating_text{
  border-bottom: 1px solid #00A0DD;
}


@media (max-width: 768px) {
  .dashboard-container {
    margin-top: -80%;
  }

  .bussinessPage__Conatiner {
    margin-bottom: 30%;
    margin-top: 30%;
  }
}