/* SpinLoader.css */
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    
  }

 
  
  