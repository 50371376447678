.cardTxt {
    font-weight: 700;
    font-size: 35px;
    margin-top: 30px;
}

.userRiews {
    font-size: 25px;
    font-family: Poppins;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 20px;

}

.rating__btn {
    border: none;
    background-color: rgba(122, 194, 79, 1);
    color: #fff;
    padding: 5px 15px 5px 15px;
    outline: none;
    border-radius: 5px;
    font-family: Poppins;
}

.all_Reviews {
    max-width: 40%;
    margin: 0 auto;
    margin-top: 50px;
    padding: 10px;
    background: none;
    color: rgba(122, 194, 79, 1);
    font-family: 'Poppins';
    border: 1px solid rgba(122, 194, 79, 1);
    outline: none;
    border-radius: 5px;
    font-size: 15px;
}

.rating__txt {
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    font-family: Poppins;
}

.total_reviews {
    font-size: 20px;
    font-family: Poppins;
    color: #8A9BAD;
    font-weight: 600;
}
.nxt__btn{
    width: 700px;
}
.btn-container{
    display: flex;
    justify-content: space-around;
    margin-inline: 40px;
    align-items: center;
    width: 90vw;
    text-align: end;
    /* margin-right: 160px; */
    margin-bottom: 100px;
}